<template>
  <div>
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      this.player.log("onPlayerReady", this);
    });
    const Button = videojs.getComponent("Button");
    class DownloadButton extends Button {
      constructor(player, options) {
        super(player, options);
        this.controlText("Download Video");
        this.addClass("vjs-icon-file-download");
      }
      handleClick() {
        const videoSrc = this.player_.currentSrc();
        const link = document.createElement("a");
        link.href = videoSrc;
        link.download = "";
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    videojs.registerComponent("DownloadButton", DownloadButton);
    this.player.getChild("controlBar").addChild("DownloadButton", {});
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
